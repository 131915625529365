* {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: 400;
  vertical-align: baseline;
  font-family: Roboto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-overflow-style: none;
  scrollbar-width: none; 
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  transition: all 0.3s ease;
  &::-webkit-scrollbar {
    display: none;
  }
}


article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
